import React from 'react'
import { FormattedMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudyOnline4baby.module.css";

// import online4babyImg from "../../assets/online4baby-deodorants.jpeg"
import online4babyImg from "../../assets/o4b-1.png"
import online4baby2Img from "../../assets/o4b-2.jpeg"
import tyvisoLogo from "../../assets/simple-logo.png"

import TestimonoalImgOne from "../../assets/o4b-toby.jpeg"
import TestimonoalImgTwo from "../../assets/adrian.jpg"

const testimonials = [
  {
    id: 'online4baby',
    img: TestimonoalImgOne,
    alt: 'Amy Metcalfe Digital Trading Manager online4baby',
    text: '"We are incredibly pleased with the results of our partnership with Tyviso. Venturing into brand collaborations for the first time, we\'ve met our objectives and enhanced our customer journey.\n\nThe seamless integration of Tyviso’s innovative Gift With Purchase platform has been a pivotal factor in elevating both customer engagement and satisfaction. The remarkable improvements we\'ve seen in basket completion and average order value reflect our dedication to providing the best shopping experience."',
    author: 'Toby Gavin, CMO, Online4Baby'
  },
  {
    id: 'tyviso',
    img: TestimonoalImgTwo,
    alt: 'Adrian Vella, CEO, Tyviso',
    text: '"This campaign has been a great example of how close collaboration can take brands from crawling to running extremely quickly.\n\nOnline4baby’s approach of going beyond just the normal, for example, also doing site banners promoting the offers, has reflected in the fantastic interaction rates they saw and, therefore, overall performance."',
    author: 'Adrian Vella, CEO, Tyviso'
  }
]

const resultList = [
  {
    normal: 'of shoppers selected a gift during their checkout, increasing perceived value',
    bold: '25% '
  },
  {
    pre: 'When a gift was selected, basket completion rose ',
    normal: 'supporting conversion rate',
    bold: '46% '
  },
  {
    pre: 'The same users also spent ',
    normal: 'more, improving AOV',
    bold: '17.2% '
  },
]

function CaseStudyOnline4baby({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}>
        <FormattedMessage id={title} />
      </h2>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={online4babyImg}
          alt="online4baby Broadband"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h3 className={styles.CaseStudySubTitle}>
            <FormattedMessage id={subTitle} />
          </h3>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="online4babyCaseFeaturedTextOne" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="online4babyCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="online4babyCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="online4babyCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="online4babyCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="online4babyCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="online4babyCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="online4babyCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="online4babyCaseApproachItemTwo" />
          </p>
          <p>
            <FormattedMessage id="online4babyCaseApproachItemThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={online4baby2Img} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="online4babyCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="online4babyCaseResultsItemOne" />
          </p>
          <ul>
            {
              resultList.map(({pre, bold, normal}, idx) => (
                <li key={`result-list--${idx}`}>
                  {pre}<strong>{bold}</strong>{normal}
                </li>
              ))
            }
          </ul>
          <p>
            <FormattedMessage id="online4babyCaseResultsItemFour" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>
    </div>
  );
};

export default CaseStudyOnline4baby;