import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudyOnline4Baby from "../../components/info-site/CaseStudyOnline4Baby";
import GetStarted from "../../components/info-site/GetStarted"

const Online4BabyCase = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="online4babyCaseTitle" descriptionId="online4babyCaseSubTitle" />
            <CaseStudyOnline4Baby
              title="online4babyCaseTitle"
              subTitle="online4babyCaseSimpleSubTitle"
            />

            <GetStarted light />
        </Layout>
    );
};

export default Online4BabyCase;